import Icons from "icons/sidebar";

const index =
  
    [
        {
          title: "Dashboard",
          Icon: Icons.DashboardIcon,
          path: "/dashboard",
        },
        {
          title: "Pipeline Statistics",
          Icon: Icons.InsightsIcon,
          path: "/pipeline-statistics",
        },

        {
          title: "User List",
          Icon: Icons.PeopleIcon,
          path: "/dashboard/user-list",
        },
        // {
        //   title: "Index Tool",
        //   Icon: Icons.TravelExploreIcon,
        //   path: "/poc",
        // },
        // {
        //   title: "Create Corporate User",
        //   Icon: Icons.AddIcon,
        //   path: "/dashboard/add-user",
        // },
        {
          title: "Corporate User List",
          Icon: Icons.CorporateFareIcon,
          path: "/dashboard/corporate/user-list",
        },
        {
          title: "DMCA Info List",
          Icon: Icons.PermDeviceInformationIcon,
          path: "/dashboard/dmca/info/list",
        },
        {
          title: "Own URLs",
          Icon: Icons.LinkIcon,
          path: "/dashboard/own-urls",
        },
        {
          title: "Index Tool",
          Icon: Icons.TravelExploreIcon,
          path: "/poc",
        },
        {
          title: "Processors",
          Icon: Icons.MemoryIcon,
          path: "/processors",
        },
        {
          title: "Notifications",
          Icon: Icons.NotificationsActiveIcon,
          path: "/notifications",
        },
        {
          title: "Subscriptions",
          Icon: Icons.PaidIcon,
          path: "/subscriptions",
        },
      ]
 

export default index;
