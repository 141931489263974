import React, { useState } from "react";
import Notification from "../../components/Notifications/Notification";
import FlexBox from "components/FlexBox";
import Alerts from "components/Notifications/Alerts";

const SelectedTab = () => {
  const [selectedTab, setSelectedTab] = useState("notifications");

  return (
    <div>
      <FlexBox
        className="toggle"
        justifyContent="center"
        sx={{ margin: "20px" }}
      >
        <FlexBox
          gap="20px"
          sx={{
            padding: "5px 10px",
            borderRadius: "10px",
            background: "white",
          }}
        >
          <span
            style={{
              cursor: "pointer",
              borderRadius: "10px",
              padding: "5px 10px",
              background: selectedTab === "notifications" ? "#408266" : "white",
              color: selectedTab === "notifications" ? "white" : "black",
              fontWeight: 500,
            }}
            onClick={() => setSelectedTab("notifications")}
          >
            Notifications
          </span>
          <span
            style={{
              cursor: "pointer",
              borderRadius: "10px",
              padding: "5px 10px",
              background: selectedTab === "alerts" ? "#408266" : "white",
              color: selectedTab === "alerts" ? "white" : "black",
              fontWeight: 500,
            }}
            onClick={() => setSelectedTab("alerts")}
          >
            Alerts
          </span>
        </FlexBox>
      </FlexBox>
      {selectedTab === "notifications" && <Notification />}
      {selectedTab === "alerts" && <Alerts />}
    </div>
  );
};

export default SelectedTab;
