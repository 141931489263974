/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import useTitle from "hooks/useTitle";
import axiosInstance from "utils/axios";
import { Grid } from "@mui/material";
import AlertsCard from "components/Notifications/AlertsCard";
import CircularProgress from "@mui/material/CircularProgress";

const Alerts = () => {
  useTitle("Notifications");
  let tempArr: any = [];
  const [alertsData, setAlertsData] = useState(tempArr);
  const [loading, setLoading] = useState(false);
  const [dataLength, setDataLength] = useState(10);
  let [page, setPage] = useState(1);

  async function fetchAlerts() {
    setLoading(true);
    try {

      const res :any= await axiosInstance.get(
        `/alerts/get-all?page=${page}`
      );
      if (res.data) {
        var result: any = res.data.data;
        setAlertsData([...alertsData, ...result]);
        setDataLength(result.length);
        setPage(page + 1); // Update page state
        setLoading(false);
      }
    } catch (error) {
      console.log(error.message);
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchAlerts();
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const { scrollTop, clientHeight, scrollHeight } =
        document.documentElement;

      if (scrollHeight - Math.round(scrollTop + clientHeight) <= 1) {
        dataLength === 10 && fetchAlerts();
      }
    };

    // Attach event listener for scrolling
    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener when component is unmounted
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [page]);

  return (
    <div>
      <Grid
        container
        spacing={{ xs: 2, sm: 2, md: 4, lg: 2 }}
        style={{ padding: "2rem" }}
      >
        {alertsData.length > 0
          ? alertsData?.map((card: any, index: number) => (
              <Grid item lg={12} md={12} xs={12} key={index}>
                <AlertsCard data={card} />
              </Grid>
            ))
          : ""}
        {loading && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              width: "100%",
              margin: "30px",
            }}
          >
            <CircularProgress sx={{ color: "#408266" }} />
          </div>
        )}
      </Grid>
    </div>
  );
};

export default Alerts;
