/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import useTitle from "hooks/useTitle";
import axiosInstance from "utils/axios";
import { Grid } from "@mui/material";
import NotificationsCard from "components/Notifications/NotificationsCard";
import CircularProgress from "@mui/material/CircularProgress";

const Notification = () => {
  useTitle("Notifications");
  let tempArr: any = [];
  const [notificationData, setNotificationData] = useState(tempArr);
  const [loading, setLoading] = useState(false);
  const [dataLength, setDataLength] = useState(10);
  let [page, setPage] = useState(1);

  async function fetchNotifications() {
    setLoading(true);
    try {

      const res: any = await axiosInstance.get(
        `/notifications/get-all?page=${page}`
      );
      if (res.data) {
        var result: any = res.data.data;
        setNotificationData([...notificationData, ...result]);
        setDataLength(result.length);
        setPage(page + 1); // Update page state
        setLoading(false);
      }
    } catch (error) {
      console.log(error.message);
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchNotifications();
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const { scrollTop, clientHeight, scrollHeight } =
        document.documentElement;

      if (scrollHeight - Math.round(scrollTop + clientHeight) <= 1) {
        dataLength === 10 && fetchNotifications();
      }
    };

    // Attach event listener for scrolling
    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener when component is unmounted
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [page]); // Add `page` to the dependency array

  return (
    <div>
      <Grid
        container
        spacing={{ xs: 2, sm: 2, md: 4, lg: 2 }}
        style={{ padding: "2rem" }}
      >
        {notificationData.length > 0
          ? notificationData?.map((card: any, index: number) => (
              <Grid item lg={12} md={12} xs={12} key={index}>
                <NotificationsCard
                  card={card}
                  loading={false}
                  key={index}
                  fetchData={fetchNotifications}
                />
              </Grid>
            ))
          : ""}
        {loading && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              width: "100%",
              margin: "30px",
            }}
          >
            <CircularProgress sx={{ color: "#408266" }} />
          </div>
        )}
      </Grid>
    </div>
  );
};

export default Notification;
