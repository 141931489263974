/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Button,
  Card,
  Divider,
  FormControl,
  Grid,
  Modal,
  Typography,
  styled,
} from "@mui/material";
import LightTextField from "components/LightTextField";
import useTitle from "hooks/useTitle";
import { FC, useState, useEffect } from "react";
import axiosInstance from "utils/axios";
import toast from "react-hot-toast";
import MenuItem from "@mui/material/MenuItem";
import LightSelectField from "components/LightSelectField";
import CloseIcon from "@mui/icons-material/Close";
import Stack from "@mui/material/Stack";
import Switch, { SwitchProps } from "@mui/material/Switch";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  "@media only screen and (max-width: 400px) and (min-width: 320px)": {
    width: "280px",
  },
};
const IOSSwitch = styled((props: SwitchProps) => (
  <Switch
    focusVisibleClassName=".Mui-focusVisible"
    name="type"
    disableRipple
    {...props}
  />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#65C466",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

type Props = {
  fetchProcessorsList: () => void;
  handleClose: () => void;
  open: any;
  editId?: any;
  processorsData?: any;
};

const AddProcessorModal: FC<Props> = ({
  open,
  handleClose,
  fetchProcessorsList,
  editId,
  processorsData,
}) => {
  // change navbar title
  useTitle("Manage Processors");
  const initialValues = {
    name: "",
    is_active: "true",
    source_type: "image",
    ip: "",
    source: "",
    type: false,
    redis_threshold: 0,
    domains: [],
  };
  var errors: any = {
    name: false,
    ip: false,
  };
  const initialFilter: any = { search: "", limit: 50 };
  const [domainsList, setDomainsList] = useState([
    {
      domain: "",
    },
  ]);
  const [values, setValues] = useState(initialValues);
  const [err, SetErr] = useState(errors);
  const [filter, setfilter] = useState(initialFilter);
  const formKeyValues: any = ["name", "ip"];

  useEffect(() => {
     const timer = setTimeout(() => {
    let queryString: any = new URLSearchParams(filter);

    getDomainList(queryString.toString());
  }, 400); // Adjust the delay as needed (e.g., 500 milliseconds)
  
  return () => {
    clearTimeout(timer);
  };
  }, [filter]);

  useEffect(()=>{
    editId && getEditData();
  },[])

  const getEditData = async () => {
    let data = await processorsData?.find((item: any) => item._id === editId);
    data =
      (await data.domains?.length) > 0
        ? { ...data, type: true }
        : { ...data, type: false };
    const objectArray: any =  data.domains?.length>0? data.domains.map((value: any) => ({
      domain: value,
    })):[];
    setValues({ ...data, domains: objectArray });
  };

  const handleChange = (e: any) => {
    let er: any = { ...err };
    er[e.target.name] = e.target.value === "";

    SetErr(er);

    let temp: any = values;

    if (e.target.name === "type") {
      values.type === false ? (temp["source"] = "") : (temp["domains"] = []);
      temp[e.target.name] = !values.type;
    } else if (e.target.name === "is_active") {
      temp[e.target.name] = temp[e.target.name] === "true" ? "false" : "true";
    } else {
      temp[e.target.name] = e.target.value;
    }
    setValues({ ...temp });
  };

  const handleDomainsChange = (e: any, v: any) => {
    // const filteredDomains: [] = v.map((obj: any) => obj.domain);
    setValues({ ...values, domains: v });
  };

  async function createUser(data: any) {
    try {
      let newData = {
        ...data,
        is_active: data.is_active === "true" ? true : false,
        domains: data.domains.map((obj: any) => obj.domain),
      };
      await axiosInstance
        .post(`/processors/add-new`, newData)
        .then((res: any) => {
          if (res.data) {
            toast.success("Successfully Added!!");
            handleClose();
            setValues(initialValues);
            fetchProcessorsList();
          }
        })
        .catch((err) => {
          console.log(err);
          toast.error(err.message);
        });
    } catch (error) {
      console.log(error.message);
    }
  }

  async function editUser(data: any, id: any) {
    try {
      let newData = {
        ...data,
        is_active: data.is_active === "true" ? true : false,
        domains: data.domains.map((obj: any) => obj.domain),
      };
      await axiosInstance
        .post(`/processors/edit/${id}`, newData)
        .then((res: any) => {
          if (res.data) {
            toast.success("Successfully Edited!!");
            handleClose();
            setValues(initialValues);
            fetchProcessorsList();
          }
        })
        .catch((err) => {
          console.log(err);
          toast.error(err.message);
        });
    } catch (error) {
      console.log(error.message);
    }
  }

  async function getDomainList(query = "") {
    try {
      // /dmca/infos?limit=500&offset=0
      await axiosInstance
        .get(`/processors/domain-info/?${query}`)
        .then((res: any) => {
          if (res.data) {
            setDomainsList(res.data.data);
            // setFilterDomains(res.data.data.data);
          }
        })
        .catch((err) => console.log(err));
    } catch (error) {
      console.log(error.message);
    }
  }

  const handleSubmit = (event: any) => {
    event.preventDefault();
    let result: any = values;

    let er: any = {};
    Object.keys(result).forEach(function (key) {
      if (formKeyValues.includes(key)) {
        er[key] = result[key] === "";
      }
    });
    SetErr(er);
    if (!Object.values(er).includes(true)) {
      editId ? editUser(values, editId) : createUser(values);
    } else {
      toast.error("Validation Error: Please fill all the Fields");
      return 0;
    }
  };



  return (
    <Box pt={2} pb={4}>
      <Grid container spacing={2}>
        <Grid item md={12} xs={12}>
          <div>
            <Modal
              keepMounted
              open={open}
              onClose={handleClose}
              aria-labelledby="keep-mounted-modal-title"
              aria-describedby="keep-mounted-modal-description"
            >
              <Box sx={style}>
                <CloseIcon
                  sx={{
                    position: "absolute",
                    top: "10px",
                    right: "10px",
                    cursor: "pointer",
                  }}
                  onClick={handleClose}
                />
                <Typography
                  id="keep-mounted-modal-title"
                  variant="h6"
                  component="h2"
                >
                  {!editId ? "Create New Processor" : "Edit Processor"}
                </Typography>
                <Typography id="keep-mounted-modal-description" sx={{ mt: 2 }}>
                  <Card sx={{ padding: 3, border: "none" }}>
                    <form onSubmit={handleSubmit}>
                      <Grid container spacing={1}>
                        <Grid item sm={6} xs={12}>
                          <LightTextField
                            fullWidth
                            name="name"
                            placeholder="Name"
                            value={values.name}
                            onChange={handleChange}
                            error={err.name}
                            helperText={err.name ? "Enter Proper Name" : ""}
                          />
                        </Grid>
                        <Grid item sm={6} xs={12}>
                          <LightTextField
                            fullWidth
                            name="ip"
                            placeholder="IP"
                            value={values.ip}
                            onChange={handleChange}
                            error={err.ip}
                            helperText={err.ip ? "Enter Proper IP" : ""}
                          />
                        </Grid>
                        <Grid item sm={6} xs={12}>
                          <LightSelectField
                            fullWidth
                            name="is_active"
                            placeholder="Type"
                            value={values.is_active}
                            onChange={handleChange}
                          >
                            <MenuItem value="true">Active</MenuItem>
                            <MenuItem value="false">Not Active</MenuItem>
                          </LightSelectField>
                        </Grid>

                        <Grid item sm={6} xs={12}>
                          <LightSelectField
                            fullWidth
                            name="source_type"
                            placeholder="Source Type"
                            value={values.source_type}
                            onChange={handleChange}
                          >
                            <MenuItem value="image">Image</MenuItem>
                            <MenuItem value="video">Video</MenuItem>
                          </LightSelectField>
                        </Grid>

                        <Grid item sm={6} lg={6} xs={12} mt={1} mb={2}>
                          <Stack
                            direction="row"
                            spacing={1}
                            alignItems="center"
                            mt={2}
                          >
                            <Typography>Source</Typography>
                            <IOSSwitch
                              onChange={handleChange}
                              sx={{ m: 1 }}
                              checked={values.type === true ? true : false}
                            />
                            <Typography>Domains</Typography>
                          </Stack>
                        </Grid>

                        {!values.type && (
                          <Grid item sm={12} xs={12}>
                            <LightTextField
                              fullWidth
                              name="source"
                              placeholder="Commercial"
                              value={values.source}
                              onChange={handleChange}
                            />
                          </Grid>
                        )}

                        {values.type && (
                          <Grid item sm={12} xs={12}>
                            <Autocomplete
                              multiple
                              id="checkboxes-tags-demo"
                              options={domainsList}
                              disableCloseOnSelect
                              value={editId ? values.domains : undefined}
                              getOptionLabel={(option) => option.domain}
                              onChange={(e, v) => handleDomainsChange(e, v)}
                              renderOption={(props, option, { selected }) => (
                                <li {...props}>
                                  <Checkbox
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    style={{ marginRight: 8 }}
                                    checked={selected}
                                  />
                                  {option.domain}
                                </li>
                              )}
                              style={{ width: "100%" }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Add Domains"
                                  placeholder="Domains"
                                  onChange={(e) =>
                                    setfilter({
                                      ...filter,
                                      search: e.target.value
                                    })
                                  }
                                />
                              )}
                            />
                          </Grid>
                        )}
                        <Grid item sm={6} xs={12} mt={1}>
                          <FormControl sx={{ minWidth: "100%" }}>
                            {/* <LightTextField
                              fullWidth
                              name="redis_threshold"
                              placeholder="Threshold Value"
                              value={values.redis_threshold}
                              onChange={handleChange}
                            /> */}
                            <TextField
                              id="outlined-basic"
                              label="Threshold Value"
                              variant="outlined"
                              name="redis_threshold"
                              placeholder="Threshold Value"
                              value={values.redis_threshold}
                              onChange={handleChange}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                          <Button type="submit" variant="contained">
                            {!editId ? "Create" : "Save"}
                          </Button>
                        </Grid>
                      </Grid>
                    </form>
                  </Card>
                </Typography>
              </Box>
            </Modal>
          </div>
        </Grid>
      </Grid>
      <Divider sx={{ my: 2 }} />
    </Box>
  );
};

export default AddProcessorModal;
