import { configureStore } from "@reduxjs/toolkit";
import dmcaInfoReducer from "./reducers/dmcaInfoReducer";

const store = configureStore({
  reducer: {
    dmcaInfoModal: dmcaInfoReducer,
  },
});

export default store;
