import Skeleton from "@mui/material/Skeleton";

interface WebSkeletonProps {
  loading?: Boolean;
  children?: any;
  sx?: any;
}
const WebSkeleton = ({ loading, children, sx }: WebSkeletonProps) => {
  if (!loading && children) {
    return <>{children}</>;
  }
  return (
    <div>
      <Skeleton sx={{ ...sx }}> {children}</Skeleton>
    </div>
  );
};

export default WebSkeleton;
