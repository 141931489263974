import { Box, Card } from "@mui/material";
import WebSkeleton from "components/WebSkeleton";
import { FC } from "react";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import useCapitalise from "hooks/useCapitalise";
import FlexBox from "components/FlexBox";
import axiosInstance from "utils/axios";
import toast from "react-hot-toast";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
const green = "#408266";
const red = "#d32f2f";
// root component interface
interface ProcessorsCardProps {
  card: any;
  loading: Boolean;
  openEditModal?: any;
  fetchProcessorsList?: any;
}

const ProcessorsCard: FC<ProcessorsCardProps> = ({
  card,
  loading,
  openEditModal,
  fetchProcessorsList,
}) => {
  const {
    _id,
    name,
    ip,
    source_type,
    is_active,
    redis_threshold,
    source,
    domains,
  } = card;

  async function editUser(id: any) {
    // eslint-disable-next-line no-restricted-globals
    if (confirm("Do you want to delete the processor")) {
      try {
        const status = {
          deletedAt: new Date(),
        };
        await axiosInstance
          .post(`/processors/edit/${id}`, status)
          .then((res: any) => {
            if (res.data) {
              toast.success("Successfully Edited!!");
              // handleClose();
              // setValues(initialValues);
              fetchProcessorsList();
            }
          })
          .catch((err) => {
            console.log(err);
            toast.error(err.message);
          });
      } catch (error) {
        console.log(error.message);
      }
    }
  }

  return (
    <WebSkeleton
      loading={loading}
      sx={{ maxWidth: "100%" }}
    >
      <Card sx={{ minWidth: 325, position: "relative", minHeight: "230px" }}>
        <CardContent style={{ paddingTop: "2rem",paddingLeft: "2rem", paddingRight: "2rem" }}>
          <Typography sx={{ fontSize: 14, fontWeight: 600 }} gutterBottom>
            {ip}
          </Typography>
          <Typography variant="h5" component="div" gutterBottom>
            {useCapitalise(name)}
          </Typography>
          <Typography
            sx={{
              mb: 1.5,
              fontWeight: 600,
              position: "absolute",
              top: "10px",
              right: "10px",
              display: "flex",
              alignItems: "center",
              color: is_active ? green : red,
            }}
            gutterBottom
          >
            <FiberManualRecordIcon
              style={{ color: is_active ? green : red }}
            />
            {is_active ? "Active" : "Not Active"}
          </Typography>
          <Typography
            variant="body2"
            style={{ color: "#97A7C6", fontWeight: 600 }}
            gutterBottom
          >
            Type : {useCapitalise(source_type)}
          </Typography>
          <Typography variant="body2" style={{ fontWeight: 600 }} gutterBottom>
            Threshold : {redis_threshold}
          </Typography>
          <Typography
            variant="body2"
            style={{ fontWeight: 600, display: "flex", gap: "10px" }}
            gutterBottom
          >
            {domains?.length > 0 ? "Domains" : "Source - "}
            {/* {domains.map((domain:any) =>{return <li>{domain}<li/>})} */}
            <FlexBox flexWrap={"wrap"} gap="5px" style={{maxHeight:"20px"}}>
              {domains?.length > 0
                ? domains.map((item: any) => {
                    return (
                      <span
                        style={{
                          borderRadius: "25px",
                          background: "#002e25",
                          color: "white",
                          padding: "4px 5px",
                          fontSize: "12px",
                        }}
                      >
                        {item}
                      </span>
                    );
                  })
                : source}
            </FlexBox>
          </Typography>
          
        </CardContent>
        <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              padding: 1,
            }}
          >
            <EditIcon
              onClick={() => openEditModal(_id)}
              sx={{
                color: "#757575",
                cursor: "pointer",
                marginRight: "10px",
              }}
            />
            <DeleteIcon
              onClick={() => editUser(_id)}
              sx={{
                color: "#757575",
                cursor: "pointer",
              }}
            />
          </Box>
      </Card>
    </WebSkeleton>
  );
};

export default ProcessorsCard;
