import AuthGuard from "components/authentication/AuthGuard";
import GuestGuard from "components/authentication/GuestGuard";
import DashboardLayout from "components/Layouts/DashboardLayout";
import LoadingScreen from "components/LoadingScreen";
import SelectedTab from "pages/notifications/SelectedTab";
import Processors from "pages/processors/Processors";
import { FC, lazy, LazyExoticComponent, Suspense } from "react";
import { Navigate } from "react-router-dom";

const Loadable = (Component: LazyExoticComponent<FC>) => (props: any) =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );

// authentication pages
const Login = Loadable(lazy(() => import("./pages/authentication/Login")));

const Register = Loadable(
  lazy(() => import("./pages/authentication/Register"))
);
const ForgetPassword = Loadable(
  lazy(() => import("./pages/authentication/ForgetPassword"))
);

// Dashboard pages
const DashboardSaaS = Loadable(lazy(() => import("./pages/dashboards/SaaS")));

// PipelineStatistics pages
// const PipelineStatistics = Loadable(
//   lazy(() => import("./pages/dashboards/PipelineStatistics"))
// );

const DashboardCharts = Loadable(
  lazy(() => import("./pages/dashboards/DashboardCharts"))
);

// POC pages
const POC = Loadable(lazy(() => import("./pages/poc/POC")));
const POCSales = Loadable(lazy(() => import("./pages/poc/POCSales")));


// user profile
const UserProfile = Loadable(lazy(() => import("./pages/UserProfile")));

// user management
const UserList = Loadable(
  lazy(() => import("./pages/userManagement/UserList"))
);

// corporate user management
const CorporateUserList = Loadable(
  lazy(() => import("./pages/corporateUsers/UserList"))
);

// corporate user view
const CorporateUserView = Loadable(
  lazy(() => import("./pages/corporateUsers/UserView"))
);

const UserGrid = Loadable(
  lazy(() => import("./pages/userManagement/UserGrid"))
);
const AddNewUser = Loadable(
  lazy(() => import("./pages/userManagement/AddNewUser"))
); 
// user profile
const UserView = Loadable(
  lazy(() => import("./pages/userManagement/UserView"))
);

// dmca-infos
const DmcaInfoList = Loadable(
  lazy(() => import("./pages/dmcainfos/DmcaInfoList"))
);

// Own URLs management
const OwnURLs = Loadable(lazy(() => import("./pages/ownURLs/OwnURLs")));
const EditOwnURL = Loadable(lazy(() => import("./pages/ownURLs/EditOwnURL")));
const Subscriptions = Loadable(lazy(() => import("./pages/subscriptions/Subscriptions")));


// error
const Error = Loadable(lazy(() => import("./pages/404")));

// routes
 export const routes = [
  {
    path: "/",
    element: <Navigate to= "dashboard" />,
  },
  {
    path: "login",
    element: (
      <GuestGuard>
        <Login />
      </GuestGuard>
    ),
  },
  {
    path: "register",
    element: (
      <GuestGuard>
        <Register />
      </GuestGuard>
    ),
  },
  {
    path: "forget-password",
    element: (
      <GuestGuard>
        <ForgetPassword />
      </GuestGuard>
    ),
  },
  {
    path: "dashboard",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <DashboardSaaS />,
      },

      {
        path: "user-profile",
        element: <UserProfile />,
      },
      {
        path: "user-list",
        element: <UserList />,
      },
      {
        path: "corporate/user-list",
        element: <CorporateUserList />,
      },
      {
        path: "dmca/info/list",
        element: <DmcaInfoList />,
      },
      {
        path: "user-grid",
        element: <UserGrid />,
      },
      {
        path: "add-user",
        element: <AddNewUser />,
      },
      {
        path: "user/:id/view",
        element: <UserView />,
      },

      {
        path: "corporate/user:id-view",
        element: <CorporateUserView />,
      },

      //OWN URLS related routes
      {
        path: "own-urls",
        element: <OwnURLs />,
      },
      {
        path: "own-url/:id",
        element: <EditOwnURL />,
      },
    ],
  },
  {
    path: "pipeline-statistics",
    element: (
      <AuthGuard>
        <DashboardLayout>
          {/* <PipelineStatistics /> */}
          <DashboardCharts />
        </DashboardLayout>
      </AuthGuard>
    ),
  },
  {
    path: "processors",
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Processors/>
        </DashboardLayout>
      </AuthGuard>
    ),
  },
  {
    path: "subscriptions",
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Subscriptions/>
        </DashboardLayout>
      </AuthGuard>
    ),
  },
  {
    path: "notifications",
    element: (
      <AuthGuard>
        <DashboardLayout>
          <SelectedTab/>
        </DashboardLayout>
      </AuthGuard>
    ),
  },
  {
    path: "poc",
    element: (
      <AuthGuard>
        <DashboardLayout>
          <POC />
        </DashboardLayout>
      </AuthGuard>
    ),
  },
  {
    path: "*",
    element: <Error />,
  },
];

 export const salesRoutes=[
  {
    path: "/",
    element: <Navigate to= "poc" />,
  },
  {
    path: "login",
    element: (
      <GuestGuard>
        <Login />
      </GuestGuard>
    ),
  },
 
  {
    path: "poc",
    element: (
      <AuthGuard>
        <DashboardLayout>
          <POCSales />
        </DashboardLayout>
      </AuthGuard>
    ),
  },
  {
    path: "*",
    element: <Error />,
  },
];

