import ReactJson from "react-json-view";
import { FC } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { formatDistanceToNow } from "date-fns";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import FlexBox from "components/FlexBox";

// root component interface
interface AlertsCardProps {
  data: any;
}

const AlertsCard: FC<AlertsCardProps> = ({ data }) => {
  const { updatedAt, user, results } = data;

  const getTimeAgoFuc = (time: any) => {
    var dateObj = new Date(time);
    const elapsedTime = formatDistanceToNow(dateObj, { addSuffix: true });
    return elapsedTime.replace("about", "");
  };

  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel2a-content"
        id="panel2a-header"
        sx={{ position: "relative", padding: 0 }}
      >
        <Typography sx={{ padding: "2%" }}>
          <div
            style={{
              position: "absolute",
              top: "5px",
              right: "2%",
              fontWeight: 700,
            }}
          >
            <span style={{ color: "black", fontWeight: 600 }}>
              {" "}
              {getTimeAgoFuc(updatedAt)}{" "}
            </span>{" "}
          </div>
          <FlexBox alignItems="center" gap="5px" fontWeight={500}>
            <div>
              Alert for <b>{user.full_name}</b>{" "}
            </div>
            <FiberManualRecordIcon sx={{ fontSize: 10 }} />
            <div>{user.email_address}</div>
          </FlexBox>
          <div style={{ fontWeight: 500 }}>
            {results.length > 0 ? (
              <span  style={{ color: "red" }}> {results.length} concerning results found</span>
            ) : (
              <span style={{ color: "#006400" }}>No results found</span>
            )}
          </div>
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography>
          <ReactJson
            src={results}
            theme="monokai" // You can choose different themes
            collapsed={true} // Set this to true for initial collapse
            collapseStringsAfterLength={20} // Adjust the length before collapsing
            enableClipboard={false} // Set this to true if you want to enable copying
            style={{ borderRadius: "5px", padding: "5px" }}
            name="body"
          />
        </Typography>
      </AccordionDetails>
    </Accordion>
  );
};

export default AlertsCard;
