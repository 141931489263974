import ReactJson from "react-json-view";
import { FC } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import useCapitalise from "hooks/useCapitalise";
import { formatDistanceToNow } from "date-fns";
import WebSkeleton from "components/WebSkeleton";
import FlexBox from "components/FlexBox";


// root component interface
interface NotificationCard {
  card: any;
  loading?: Boolean;
  openEditModal?: any;
  fetchData?: any;
}

const NotificationsCard: FC<NotificationCard> = ({
  card,
  loading,
  openEditModal,
  fetchData,
}) => {
  const { type, module, updatedAt, message, status, body } = card;

  const getTimeAgoFuc = (time: any) => {
    var dateObj = new Date(time);
    const elapsedTime = formatDistanceToNow(dateObj, { addSuffix: true });
    return elapsedTime.replace("about", "");
  };
  

  return (
    <WebSkeleton loading={loading} sx={{minWidth:"100%",minHeight:"100%"}}>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
          sx={{ position: "relative", padding: 0 }}
        >
          <Typography sx={{ padding: "2%" }}>
            <div
              style={{
                position: "absolute",
                top: "5px",
                right: "2%",
                color: status === "success" ? "green" : "red",
                fontWeight: 700,
              }}
            >
              <span style={{ color: "black", fontWeight: 600 }}>
                {" "}
                {getTimeAgoFuc(updatedAt)}{" "}
              </span>{" "}
              • {status === "success" ? "Success" : "Error"}
            </div>
            <FlexBox flexDirection={"row"} sx={{width:"100%"}}>
            <div
              style={{
                
                fontSize: "12px",
                background: "#408266",
                color: "white",
                // padding: "6px 10px",
                // width:"20%",
                padding:"3px 10px",
                alignSelf:"center",
                borderRadius: "5px",
              }}
            >
              {useCapitalise(type)}
            </div>
            <div className="name" style={{ padding: "5px 10px", fontWeight: 600 }}>
            • {body["takedowns"]
                ? body["takedowns"][0]?.user_info.full_name || ""
                : body["user"][0]?.full_name || ""} 
            </div>
            <div
              style={{
                // background: "#408266",
                // color: "white",
                display: "flex",
                alignItems: "center",
                gap: 5,
                // padding: "5px 10px",
                // borderRadius: "8px",
              }}
            >
              
             <b> • </b> Notification for{" "}
              <span
                style={{
                  fontWeight: 700,
                }}
              >
                {" "}
                {useCapitalise(module)} :
              </span>{" "}
              <div style={{ fontWeight: 600 }}>
                {" "}
                {useCapitalise(message)} <hr style={{ margin: 0 }} />
              </div>
            </div>
            </FlexBox>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <ReactJson
              src={body}
              theme="monokai" // You can choose different themes
              collapsed={true} // Set this to true for initial collapse
              collapseStringsAfterLength={20} // Adjust the length before collapsing
              enableClipboard={false} // Set this to true if you want to enable copying
              style={{borderRadius:"5px",padding:"5px"}}
              name="body"
            />
          </Typography>
        </AccordionDetails>
      </Accordion>
    </WebSkeleton>
  );
};

export default NotificationsCard;
