import { CssBaseline, ThemeProvider } from "@mui/material";
import { StyledEngineProvider } from "@mui/material/styles";
import { FC } from "react";
import { Toaster } from "react-hot-toast";
import { useRoutes } from "react-router-dom";
import "react-loading-skeleton/dist/skeleton.css";

import { routes, salesRoutes } from "./routes";
import { ukoTheme } from "./theme";

const App: FC = () => {
  const allPages = useRoutes(routes);
  const salesPages = useRoutes(salesRoutes);

  // App theme
  const appTheme = ukoTheme();

  const role: any = localStorage.getItem("userRole");
  // useEffect(() => {
  //   console.log(role, "userRole in useEffect");

  //   setUserRole(role);
  // }, []);
  // console.log(userRole, "userRole in app.js");

  // toaster options
  const toasterOptions = {
    style: {
      fontWeight: 500,
      fontFamily: "'Montserrat', sans-serif",
    },
  };

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={appTheme}>
        <CssBaseline />
        <Toaster toastOptions={toasterOptions} />
        {role !== "sales" ? allPages : salesPages}
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default App;
