import { createSlice } from "@reduxjs/toolkit";

const dmcaInfoSlice = createSlice({
  name: "dmcaInfo",
  initialState: {
    dmcaInfoModalOpen: false,
    selectedDmcaInfo: false,
  },
  reducers: {
    dmcaInfoModal: (state, action) => {
      state.dmcaInfoModalOpen = action.payload === "open" ? true : false;
      return state;
    },
    selectDmcaInfo: (state, action) => {
      state.selectedDmcaInfo = action.payload;
      return state;
    },
  },
});

export const { dmcaInfoModal, selectDmcaInfo } = dmcaInfoSlice.actions;
export default dmcaInfoSlice.reducer;
