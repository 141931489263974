import useTitle from "hooks/useTitle";
import { Grid, Button } from "@mui/material";
import ProcessorsCard from "components/Processors/ProcessorsCard";
import axiosInstance from "utils/axios";
import { useEffect, useState } from "react";
import WebSkeleton from "components/WebSkeleton";
import AddProcessorModal from "components/Processors/AddProcessorModal";
import AddIcon from "@mui/icons-material/Add";
const Processors = () => {
  useTitle("Processors");
  const [processorsData, setProcessorData] = useState([]);
  const [loaderShow, setLoaderShow] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [editId, setEditId] = useState();

  async function fetchProcessorsData() {
    try {
      setLoaderShow(true);

      await axiosInstance
        .get(`/processors/get-all`)
        .then((res: any) => {
          if (res.data) {
            var result = res.data.data;
            setProcessorData(result);
            setLoaderShow(false);
          }
        })
        .catch((err) => {
          console.log(err);
          setLoaderShow(false);
        });
    } catch (error) {
      console.log(error.message);
      setLoaderShow(false);
    }
  }

  const handleClose = () => {
    setShowAddModal(false);
  };

  useEffect(() => {
    fetchProcessorsData();
  }, []);

  const openEditModal = (id: any) => {
    setEditId(id);
    setShowAddModal(true);
  };

  const openAddNewModal = () => {
    setEditId(undefined);
    setShowAddModal(true);
  };

  return (
    <div>
      <Grid
        item
        xs={12}
        sx={{
          textAlign: "right",
          marginTop: "10px"
        }}
      >
        <Button type="submit" variant="contained"    onClick={() => openAddNewModal()}>
            <AddIcon />  Create Processor
          </Button>
      </Grid>
      {showAddModal && (
        <AddProcessorModal
          open={showAddModal}
          handleClose={handleClose}
          fetchProcessorsList={fetchProcessorsData}
          editId={editId}
          processorsData={processorsData}
        />
      )}
      <Grid
        container
        spacing={{ xs: 2, sm: 2, md: 4 }}
        style={{ paddingTop: "1rem", paddingBottom: "1rem" }}
      >
        {processorsData.length > 0
          ? processorsData
              
              ?.map((card: any, index: number) => (
                <Grid item lg={3} md={6} xs={12} key={index}>
                  <WebSkeleton
                    loading={loaderShow}
                    sx={{ maxWidth: "100%", maxHeight: "100%" }}
                  >
                    <ProcessorsCard
                      card={card}
                      loading={false}
                      key={index}
                      openEditModal={openEditModal}
                      fetchProcessorsList={fetchProcessorsData}
                    />
                  </WebSkeleton>
                </Grid>
              ))
          : ""}
      </Grid>
    </div>
  );
};

export default Processors;
